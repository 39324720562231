import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className="menu-item-has-children">
          <Link to="#">
            Nosotros
          </Link>
        </li>
        <li className="menu-item-has-children">
          <Link to="#">
            Industrias 
          </Link>
        </li>
        <li className="menu-item-has-children">
          <Link to="#">
            Clientes 
          </Link>
        </li>
        <li className="menu-item-has-children">
          <Link to="#">
            Eventos 
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
